/* eslint-disable jsx-a11y/anchor-is-valid */
import React , { useEffect, useState } from 'react';
import { ContentHeader } from '@components';
import api from '@app/utils/axios';
import axios from 'axios';
import 'react-data-grid/lib/styles.css';

import ReactDOM from 'react-dom';
import { useNavigate } from 'react-router-dom';

import Modal from 'react-modal';
import { useFormik, Formik, Field, Form } from 'formik';
import { PfButton, PfCheckbox } from '@profabric/react-components';
import { InputGroup } from 'react-bootstrap';
import * as Yup from 'yup';
import "ka-table/style.css";

import { Table, useTable } from 'ka-table';
import { ActionType, DataType, EditingMode, SortingMode, SortDirection } from 'ka-table/enums';
import { toast } from 'react-toastify';
import { useGlobalState } from '@app/utils/state';


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

function Negocio() {
    const [atulizaPipe, setAtulizaPipe] = useGlobalState('atualizaPipeline');
    const [modalIsOpen, setmodalIsOpen] = useState(false);
    const [setIsOpen, setsetIsOpen] = useState(false);
    const [isLoadingForm, setisLoadingForm] = useState(false);
    const [submitting,setSubmitting]= useState(false);
    const handleSubmit = event => {
        event.preventDefault();
        setSubmitting(true);

        setTimeout(() => {
            setSubmitting(false);
        }, 3000);
    }
    const sleep = async (v) => {

        //var self = this;
        console.log(v.etapa_funil_id)
        setisLoadingForm(true)
        console.log("debug 123"+atulizaPipe);
        //await api.post("http://localhost:3333/negocios", v)
        await api.post("https://crm-imjg.onrender.com/negocios", v)
            .then(async function (response) {
                console.log(response);
                setAtulizaPipe(true)
                //teste
                setsetIsOpen(false)
                setmodalIsOpen(false)
                
                //teste
                toast.success('salvou');
                setisLoadingForm(false)
                console.log("debug 1234"+atulizaPipe);
            })
            .catch((err) => {
                // this.setState({ data: err, isLoading: false });
            });
    }
    const openModal =()=> {
        setsetIsOpen(true);
        setmodalIsOpen(true);
    }

    const closeModal =()=> {
        console.log("bbbbb");
        setsetIsOpen(false);
        setmodalIsOpen(false);
        
    }
    return(
        


        
            <div>

                <li className="nav-item d-none d-sm-inline-block">
                    <button
                        onClick={openModal}
                        type="button"
                        className="nav-link"
                    >
                        Novo Negocio
                    </button>
                </li>

                <Modal
                    isOpen={modalIsOpen}
                    //onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div className="card-body">
                        <p className="login-box-msg">Novo Negocio</p>
                        <Formik
                            initialValues={{
                                titulo: '',
                                etapa_funil_id:1
                            }}
                            onSubmit={async (values) => {
                                await sleep(values)
                                //alert(JSON.stringify(values, null, 2));
                            }}
                        >
                            {({ isSubmitting }) => (
                                <Form className="form-horizontal">
                                    <div className="form-group row">
                                        <label htmlFor="inputName" className="col-sm-2 col-form-label">
                                            Titulo
                                        </label>
                                        <div className="col-sm-10">
                                            <Field name="titulo" className="form-control" placeholder="Titulo" />
                                        </div>
                                        <div className="col-sm-10">
                                            <Field  type="Number" name="etapa_funil_id" className="form-control" placeholder="Titulo" />
                                        </div>
                                    </div>

                                    <button type="submit" className={isLoadingForm ? "loading" : 'btn btn-primary'} disabled={isSubmitting}>
                                        Salvar
                                    </button>
                                </Form>
                            )}
                        </Formik>
                    </div>

                    <button style={{ display: 'none' }} onClick={closeModal} className="btn btn-primary">Fechar</button>
                </Modal>

            </div>
        )
  
}
export default Negocio;
