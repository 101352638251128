/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { ContentHeader } from '@components';
import api from '@app/utils/axios';
import axios from 'axios';
import 'react-data-grid/lib/styles.css';

import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { useFormik, Formik, Field, ErrorMessage } from 'formik';
import { PfButton, PfCheckbox } from '@profabric/react-components';

import * as Yup from 'yup';
import "ka-table/style.css";

import { Table, useTable } from 'ka-table';
import { ActionType, DataType, EditingMode, SortingMode, SortDirection } from 'ka-table/enums';
import { toast } from 'react-toastify';
import { ICellTextProps } from 'ka-table/props';
import { Form, InputGroup } from 'react-bootstrap';

const customStyles = {
  content: {
    top: '45px',
    /* left: '50%',
     right: 'auto',
     bottom: 'auto',
     marginRight: '-50%',
     transform: 'translate(-50%, -50%)',
     */
  },
};

function Usuarios() {
  const [modalIsOpen, setmodalIsOpen] = useState(false);
  const [setIsOpen, setsetIsOpen] = useState(false);
  const [isLoadingForm, setisLoadingForm] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [novocadastro, setNovocadastro] = useState(false);
  const [data, setData] = useState([]);
  const [persons, setPersons] = useState([]);
  const [usuario, setUsuario] = useState({ id:'',nome: '', email: '', senha: '' });
  const [isLoading, setIsLoading] = useState(false);


  const openModal = () => {
    setsetIsOpen(true);
    setmodalIsOpen(true);
    setUsuario({ id:'',nome: '', email: '', senha: '' })
  }

  const closeModal = async () => {
    setsetIsOpen(false);
    setmodalIsOpen(false);
    setUsuario({  id:'',nome: '', email: '', senha: '' })
    /*
    table.showLoading();
    const response = await fetch('https://crm-imjg.onrender.com/usuarios');
    const jsonData = await response.json();
    console.log(jsonData);
    const data = jsonData.usuarios;
    setData(data);
    table.hideLoading();
  */
  }

  const sleep = async (v) => {
    setisLoadingForm(true);
    setIsLoading(true)
    if (!v.id) {
      await api.post("https://crm-imjg.onrender.com/usuarios", v)
        .then(async function (response) {
          console.log(response);
          //teste
          setsetIsOpen(false);
          setmodalIsOpen(false);

          table.showLoading();
          const response2 = await fetch('https://crm-imjg.onrender.com/usuarios');
          const jsonData = await response2.json();
          console.log(jsonData);
          const data = jsonData.usuarios;
          setData(data);
          table.hideLoading();

          //teste
          toast.success('salvou');
          setisLoadingForm(false);
          console.log(isLoadingForm);
        })
        .catch((err) => {
          //toast.error('Erro ao salvar',err.response.data.message);
          toast.error("Erro ao salvar");
        });
    } else {
      await api.put("https://crm-imjg.onrender.com/usuarios/" + v.id, v)
        .then(async function (response) {
          console.log(response);
          //teste
          setsetIsOpen(false);
          setmodalIsOpen(false);

          table.showLoading();
          const response2 = await fetch('https://crm-imjg.onrender.com/usuarios');
          const jsonData = await response2.json();
          console.log(jsonData);
          const data = jsonData.usuarios;
          setData(data);
          table.hideLoading();

          //teste
          toast.success('salvou');
          setisLoadingForm(false);
          console.log(isLoadingForm);
        })
        .catch((err) => {
          toast.error('Erro ao salvar', err.message);
        });
    }
    setIsLoading(false)
  }
  /*
  const handleSubmit = event => {
    event.preventDefault();
    setSubmitting(true);

    setTimeout(() => {
      setSubmitting(false);
    }, 3000);
  }
  */
  useEffect(() => {

    console.log("debug56")
    if (novocadastro) {
      table.showLoading();
      console.log("debug2")
      api.get(`https://crm-imjg.onrender.com/usuarios`)
        .then(res => {
          const persons2 = res.data.usuarios;
          setPersons(persons2)
          setNovocadastro(false)
          table.hideLoading();
        })
    }
  });

  const table = useTable({
    onDispatch: async (action) => {
      if (action.type === ActionType.ComponentDidMount) {
        table.showLoading();
        const response = await fetch('https://crm-imjg.onrender.com/usuarios');
        const jsonData = await response.json();
        console.log(jsonData);
        const data = jsonData.usuarios;
        setData(data);
        table.hideLoading();
      }
    }
  });

  const EditRow: React.FC<ICellTextProps> = ({
    dispatch, rowKeyValue,
  }) => {
    return (
      <i className="fas fa-pen" onClick={() => (editRow(rowKeyValue))} />

    );
  };
  /*
  const handleChange = event => {
    setUsuario(prevValues => ({
      ...prevValues,
      // we use the name to tell Formik which key of `values` to update
      [event.target.name]: event.target.value
    }))
  }
  */

  const editRow = async (id) => {
    setIsLoading(true)
    setsetIsOpen(true);
    setmodalIsOpen(true);

    const response = await fetch('https://crm-imjg.onrender.com/usuario/' + id);
    const jsonData = await response.json();
    console.log(jsonData);
    const data1 = jsonData.usuario;
    setUsuario(data1[0]);

    setIsLoading(false)
  }


  const { handleChange, values, handleSubmit, touched, errors } = useFormik({
    enableReinitialize: true,
    initialValues: {
      id: usuario.id,
      nome: usuario.nome,
      email: usuario.email,
      senha: usuario.senha
    },
    validationSchema: Yup.object({
      email: Yup.string().email('Email invalido').required('Obrigadotorio'),
      nome: Yup.string().required('Obrigadotorio')

    }),
    onSubmit: (values) => {
      sleep(values);
    }
  });
  return (

    <div>
      <ContentHeader title="Usuarios do sistema" />
      <section className="content">
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title"></h3>
              <div className="card-tools">
                <button className="btn btn-primary"
                  onClick={openModal}>Novo</button>
              </div>
            </div>
            <div className="card-body">
              <Table
                columns={[
                  { key: 'id', title: 'id', dataType: DataType.String },
                  { key: 'nome', title: 'Nome', dataType: DataType.String },
                  { key: 'email', title: 'Email', dataType: DataType.String },
                  { key: 'criadoEm', title: 'CriadoEm', dataType: DataType.Date, sortDirection: SortDirection.Descend },
                  { key: ':edit', width: 70, style: { textAlign: 'center' } },
                ]}
                data={data}
                table={table}
                //editingMode={EditingMode.Cell}
                rowKeyField={'id'}
                sortingMode={SortingMode.Single}
                childComponents={{
                  cellText: {
                    content: (props) => {
                      switch (props.column.key) {
                        case ':delete':
                          //alert('123')
                          break;
                        case ':edit':
                          return <EditRow {...props} />

                      }
                    }
                  }
                }}
              />

              <Modal
                isOpen={modalIsOpen}
                //onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                className="modal-dialog modal-lg"
                style={customStyles}
                contentLabel="Example Modal"
              >
                <div className="overlay" style={{ display: isLoading ? '' : 'none' }} >
                  <i className="fas fa-3x fa-sync-alt fa-spin" style={{ color: "white" }}></i></div>

                <div className="modal-content" >
                  <div className="modal-header">
                    <h4 className="modal-title">Large Modal</h4>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>

                  <div className="modal-body">
                    <form onSubmit={handleSubmit}>
                      <div className="mb-3">
                        <InputGroup className="mb-3">
                          <Form.Control
                            id="nome"
                            name="nome"
                            type="text"
                            placeholder="nome"
                            onChange={handleChange}
                            value={values.nome}
                            isValid={touched.nome && !errors.nome}
                            isInvalid={touched.nome && !!errors.nome}
                          />
                          {touched.nome && errors.nome ? (
                            <Form.Control.Feedback type="invalid">
                              {errors.nome}
                            </Form.Control.Feedback>
                          ) : (
                            <InputGroup.Append>
                              <InputGroup.Text>
                                <i className="fas fa-envelope" />
                              </InputGroup.Text>
                            </InputGroup.Append>
                          )}
                        </InputGroup>
                      </div>
                      <div className="mb-3">
                        <InputGroup className="mb-3">
                          <Form.Control
                            id="email"
                            name="email"
                            type="email"
                            placeholder="Email"
                            onChange={handleChange}
                            value={values.email}
                            isValid={touched.email && !errors.email}
                            isInvalid={touched.email && !!errors.email}
                          />
                          {touched.email && errors.email ? (
                            <Form.Control.Feedback type="invalid">
                              {errors.email}
                            </Form.Control.Feedback>
                          ) : (
                            <InputGroup.Append>
                              <InputGroup.Text>
                                <i className="fas fa-envelope" />
                              </InputGroup.Text>
                            </InputGroup.Append>
                          )}
                        </InputGroup>
                      </div>
                      <div className="mb-3">
                        <InputGroup className="mb-3">
                          <Form.Control
                            id="senha"
                            name="senha"
                            type="text"
                            placeholder="senha"
                            onChange={handleChange}
                            value={values.senha}
                            isValid={touched.senha && !errors.senha}
                            isInvalid={touched.senha && !!errors.senha}
                          />
                          {touched.senha && errors.senha ? (
                            <Form.Control.Feedback type="invalid">
                              {errors.senha}
                            </Form.Control.Feedback>
                          ) : (
                            <InputGroup.Append>
                              <InputGroup.Text>
                                <i className="fas fa-envelope" />
                              </InputGroup.Text>
                            </InputGroup.Append>
                          )}
                        </InputGroup>
                      </div>
                      <Form.Control type="hidden" name="id" className="form-control" placeholder="id" />
                      <div className="modal-footer justify-content-between">
                        <PfButton type="button" className="btn btn-default" onClick={closeModal}>Fechar</PfButton>
                        <PfButton type="submit" className="btn btn-primary">
                          Salvar
                        </PfButton>
                      </div>
                    </form>




                  </div>
                </div>
              </Modal>

            </div>
            <div className="card-footer"></div>
          </div >
        </div >
      </section >
    </div >
  );

}
export default Usuarios;

/*
<Formik
enableReinitialize={true}
initialValues={{
  nome: '', email: ''
}}
validationSchema={UsuarioSchema}
onSubmit={async (values) => {
  await sleep(values)
}}
>
{({ isSubmitting, errors, touched }) => (
  <div >
    <Form className="form-horizontal">
      <div className="form-group row">
        <label htmlFor="inputName" className="col-sm-2 col-form-label">
          Nome
        </label>
        <Field name="nome"
          onChange={handleChange}
          value={usuario.nome}

          className="form-control form-control-border" placeholder="Nome"

        />
        {errors.nome && touched.nome ? (
          <div>{errors.nome}</div>
        ) : null}

      </div>
      <div className="form-group">
        <label htmlFor="email">Email</label>
        <Field name="email" onChange={handleChange}
          value={usuario.email} className="form-control form-control-border" placeholder="jane@acme.com" type="email" />
      </div>
      <div className="form-group">
        <label htmlFor="senha" >Senha</label>
        <Field name="senha" className="form-control form-control-border" placeholder="Senha" />
      </div>
      <Field type="hidden" name="id" className="form-control" placeholder="id" />
      <div className="modal-footer justify-content-between">
        <button type="button" className="btn btn-default" onClick={closeModal}>Fechar</button>
        <button type="submit" className={isLoadingForm ? "btn btn-primary loading" : 'btn btn-primary'} disabled={isSubmitting}>
          Salvar
        </button>
      </div>
    </Form>

  </div>
)}

</Formik>
*/