/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {ContentHeader} from '@components';
import api from '@app/utils/axios';
import axios from 'axios';
export default class Blank extends React.Component {
state = {
  persons: []
}
componentDidMount() {
  console.log("aaaaaaaaa")
  
  api.get(`https://crm-imjg.onrender.com/usuarios`)
    .then(res => {
      const persons = res.data.usuarios;
      this.setState({ persons });
    })
    
    console.log(this.state.persons)
}

  
  render() {
    return(
    <div>
      <ContentHeader title="Blank Page" />
      <section className="content">
        <div className="container-fluid">
          <div className="card">
            <div className="card-header">
              <h3 className="card-title">aaaaaaaTitle</h3>
              <div className="card-tools">
                <button
                  type="button"
                  className="btn btn-tool"
                  data-widget="collapse"
                  data-toggle="tooltip"
                  title="Collapse"
                >
                  <i className="fa fa-minus" />
                </button>
                <button
                  type="button"
                  className="btn btn-tool"
                  data-widget="remove"
                  data-toggle="tooltip"
                  title="Remove"
                >
                  <i className="fa fa-times" />
                </button>
              </div>
            </div>
            <div className="card-body">
              Start creating your amazing application!
                   <ul>
        {
          this.state.persons
            .map(person =>
              <li key={person.id}>{person.nome}</li>
            )
        }
      </ul>
            </div>
            <div className="card-footer">Footer</div>
          </div>
        </div>
      </section>
    </div>
    )
};
}
