import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PfDropdown } from '@profabric/react-components';
import styled from 'styled-components';

import Etapa from '@app/modules/main/header/configuracao/Etapa';

export const StyledDropdown = styled(PfDropdown)`
  border: none;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  --pf-dropdown-menu-min-width: 10rem;

  .dropdown-item {
    padding: 0.5rem 1rem;
  }

  .text-sm {
    margin-bottom: 0;
  }
  .dropdown-divider {
    margin: 0;
  }
`;

export interface Language {
    key: string;
    icon: string;
    label: string;
}



const Configuracao = () => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
 



    return (

      <>
       <Etapa />
      
   
        </>
    );
};
/*
<StyledDropdown isOpen={dropdownOpen} hideArrow>
<div className="nav-link" slot="button">
    <i className={`flag-icon`} />
    teste
</div>
<div slot="menu">
   
</div>
</StyledDropdown>
*/
export default Configuracao;
