/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { ContentHeader } from '@components';
import api from '@app/utils/axios';
import axios from 'axios';
import 'react-data-grid/lib/styles.css';

import ReactDOM from 'react-dom';
import { useNavigate } from 'react-router-dom';

import Modal from 'react-modal';
import { useFormik, Formik, Field, Form } from 'formik';
import { PfButton, PfCheckbox } from '@profabric/react-components';
import { InputGroup } from 'react-bootstrap';
import * as Yup from 'yup';
import "ka-table/style.css";

import { Table, useTable } from 'ka-table';
import { ActionType, DataType, EditingMode, SortingMode, SortDirection } from 'ka-table/enums';
import { toast } from 'react-toastify';


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

export default class Etapa extends React.Component {
    constructor() {
        super()

        this.state = {
            persons: [],
            modalIsOpen: false,
            setIsOpen: false,
            data: '',
            isLoading: false,
            titulo: '',
            etapa_funil_id: '',
            novocadastro: false,
            isLoadingForm: false
        }
    }
    

    componentDidMount() {
        console.log("aaaaaaaaa")
        /*
        api.get(`https://crm-imjg.onrender.com/usuarios`)
            .then(res => {
                const persons = res.data.usuarios;
                this.setState({ persons });
            })
        console.log(this.state.persons)
        */
    }
    useEffect() {
        console.log("debug56")
        console.log(this.state.novocadastro)
        if (this.state.novocadastro) {
            /*
            console.log("debug2")
            api.get(`https://crm-imjg.onrender.com/usuarios`)
                .then(res => {
                    const persons = res.data.usuarios;
                    this.setState({ persons });
                })
                */
        }
    }
    componentDidUpdate() {
        console.log("debug1")
        console.log(this.state.novocadastro)
        if (this.state.novocadastro) {
            /*
            console.log("debug2")
            api.get(`https://crm-imjg.onrender.com/usuarios`)
                .then(res => {
                    const persons = res.data.usuarios;
                    this.setState({ persons });
                })
                */
        }

    }

    handleSubmit = event => {
        event.preventDefault();
        setSubmitting(true);

        setTimeout(() => {
            setSubmitting(false);
        }, 3000);
    }

    handleChange = event => {
        /*  setFormData({
            name: event.target.name,
            value: event.target.value,
          });
          */
    }
    render() {

        const sleep = async (v) => {

            var self = this;
            console.log(v.etapa_funil_id)
            this.setState({ isLoadingForm: true });

          //  await api.post("http://localhost:3333/etapa_funil", v)
            await api.post("https://crm-imjg.onrender.com/etapa_funil", v)
                .then(async function (response) {
                    // this.setState({ data: response.data, isLoading: false });
                    //this.setState({ novocadastro: true });
                    console.log(response);

                    //teste
                    self.setState({ setIsOpen: false });
                    self.setState({ modalIsOpen: false });

                    //teste
                    toast.success('salvou');
                    self.setState({ isLoadingForm: false });
                    console.log(isLoadingForm);
                })
                .catch((err) => {
                    // this.setState({ data: err, isLoading: false });
                });
        }
        function openModal() {
            this.setState({ setIsOpen: true });
            this.setState({ modalIsOpen: true });
        }


        async function closeModal() {
            this.setState({ setIsOpen: false });
            this.setState({ modalIsOpen: false });


            /*table.showLoading();
            const response = await fetch('https://crm-imjg.onrender.com/usuarios');
            const jsonData = await response.json();
            console.log(jsonData);
            const data = jsonData.usuarios;
            this.setState({ data });*/
            // table.hideLoading();
        }


        return (
            <div>

                <button
                    type="button"
                    className={`dropdown-item`}
                    onClick={openModal.bind(this)}
                >
                    
                    <i className={`flag-icon  mr-2`} />
                    <span>+ Etapa</span>
                </button>

                <Modal
                    isOpen={this.state.modalIsOpen}
                    //onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal.bind(this)}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div className="card-body">
                        <p className="login-box-msg">Nova etapa do processo de vendas</p>
                        <Formik
                            initialValues={{
                                nome: '',
                                funil_id: 1,
                                posicao:0
                            }}
                            onSubmit={async (values) => {
                                await sleep(values)
                                //alert(JSON.stringify(values, null, 2));
                            }}
                        >
                            {({ isSubmitting }) => (
                                <Form className="form-horizontal">
                                    <div className="form-group row">
                                        <label htmlFor="inputName" className="col-sm-2 col-form-label">
                                            Titulo
                                        </label>
                                        <div className="col-sm-10">
                                            <Field name="nome" className="form-control" placeholder="Titulo" />
                                        </div>
                                        <label htmlFor="inputName" className="col-sm-2 col-form-label">
                                            Posição
                                        </label>
                                        <div className="col-sm-10">
                                            <Field type="Number" name="posicao" className="form-control" placeholder="posicao" />
                                        </div>
                                    </div>

                                    <button type="submit" className={this.state.isLoadingForm ? "loading" : 'btn btn-primary'} disabled={isSubmitting}>
                                        Salvar
                                    </button>
                                </Form>
                            )}
                        </Formik>
                    </div>

                    <button style={{ display: 'none' }} onClick={closeModal.bind(this)} className="btn btn-primary">Fechar</button>
                </Modal>

            </div>
        )
    };
}
