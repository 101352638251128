import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';

import translationEN from '../locales/en/translation.json';
import translationTR from '../locales/tr/translation.json';
import translationDE from '../locales/de/translation.json';
import translationES from '../locales/es/translation.json';
import translationFR from '../locales/fr/translation.json';
import translationPTBR from '../locales/ptbr/translation.json';
// the translations
const resources = {
  en: {
    translation: translationEN
  },
  tr: {
    translation: translationTR
  },
  es: {
    translation: translationES
  },
  fr: {
    translation: translationFR
  },
  de: {
    translation: translationDE
  },
  ptbr: {
    translation: translationPTBR
  }
};

i18n
  .use(initReactI18next as any) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'ptbr',
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    react: {
      wait: true
    }
  } as any);

export default i18n;
