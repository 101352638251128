import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import uuid from "uuid/v4";
import api from '@app/utils/axios';
import { isHtmlElement } from "react-router-dom/dist/dom";
import { useGlobalState } from '@app/utils/state';


const itemsFromBackend = [
  { id: uuid(), content: "First task" },
  { id: uuid(), content: "Second task" },
  { id: uuid(), content: "Third task" },
  { id: uuid(), content: "Fourth task" },
  { id: 'bbbbbbbb', content: "negocios" }
];

const columnsFromBackend = {
  ['ccccccccc']: {
    name: "colunas do pipeline",
    items: itemsFromBackend
  },
  [uuid()]: {
    name: "teste2",
    items: []
  },
  [uuid()]: {
    name: "In Progress",
    items: []
  },
  [uuid()]: {
    name: "Done",
    items: []
  }
};
//console.log(columnsFromBackend)
var isIni = true
const onDragEnd = (result, columns, setColumns) => {
  if (!result.destination) return;
  const { source, destination } = result;

  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId];
    const destColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);
    destItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems
      },
      [destination.droppableId]: {
        ...destColumn,
        items: destItems
      }
    });
    console.log("debug1")
    console.log(destination.droppableId + "<<<funil")
    console.log(result.draggableId + "<<<negocio")
    api.get(`https://crm-imjg.onrender.com/usuarios`)
      .then(res => {
        console.log("salvei negocio")
      })
  } else {
    const column = columns[source.droppableId];
    const copiedItems = [...column.items];
    const [removed] = copiedItems.splice(source.index, 1);
    copiedItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...column,
        items: copiedItems
      }
    });
  }
};

function App() {
  const [columns, setColumns] = useState('');
  const [init, setIni] = useState(true);
  const [atulizaPipe, setAtulizaPipe] = useGlobalState('atualizaPipeline');
  const [isLoading, setisLoading] = useState(false);
  useEffect(() => {
    console.log("debug 125"+atulizaPipe);
   async function axiosTest() {
    setisLoading(true)
      const response = await fetch('https://crm-imjg.onrender.com/funil/1');
      // const response = await fetch('http://localhost:3333/funil/1');
      const jsonData = await response.json();
      setIni(false)
      setColumns(jsonData.funil)
      setisLoading(false)
    }
    // if (isIni) {
    if (init) {
      
      axiosTest();
      //setisLoading(true)
    }
    if (atulizaPipe) {
     // s etisLoading(true)
      axiosTest();
      setAtulizaPipe(false)
     // setisLoading(true)
    }
  });
  const divStyle = {
  left: 50,
  position: 'fixed',
  top: 50,
  zindex: 99999,
  }
  const teste = {
    display:'none'
    }
  return (
    <div className="content-wrapper kanban"
      ref={(el) => {
        if (el) {
          el.style.setProperty('margin-left', '11px', 'important');
        }
      }} >
      <section className="content">
    <div  className="overlay" style={ { display: isLoading ? '' : 'none' } } ><i className="fas fa-3x fa-sync-alt fa-spin"></i><div className="text-bold pt-2">Loading...</div></div>
        <div className="container-fluid h-100 " style={ { opacity: isLoading ? '0.1' : '' } }>
          <DragDropContext
            onDragEnd={result => onDragEnd(result, columns, setColumns)}
          >
            {Object.entries(columns).map(([columnId, column], index) => {
              return (
                <div
                  className="card card-row card-primary "
                  key={columnId}
                >
                  <div className="card-header">
                    <h3 className="card-title">
                    {column.name}
                    </h3>
                  </div>
                  <div className="card-body">
                    <Droppable droppableId={columnId} key={columnId}>
                      {(provided, snapshot) => {
                        return (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={{
                              background: snapshot.isDraggingOver
                                ? "lightgrey"
                                : "",
                              minHeight: 500
                            }}
                          >
                            {column.items.map((item, index) => {
                              return (
                                <Draggable
                                  key={item.id}
                                  draggableId={item.id.toString()}
                                  index={index}
                                >
                                  {(provided, snapshot) => {
                                    return (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}

                                      >
                                        <div className="card card-primary card-outline shadow" style={{ marginBottom: 10 }}>
                                          <div className="card-header">
                                            <h5 className="card-title">{item.titulo}</h5>
                                            <div className="card-tools">
                                              <span className="text-muted text-sm ">R$ {item.valor}</span>
                                              <a href="#" className="btn btn-tool">
                                                <i className="fas fa-pen"></i>
                                              </a>
                                            </div>
                                          </div>
                                          <div className="card-body">
                                           <p>
                                              Expectativa {item.data_expectativa}
                                            </p>
                                            <p>
                                              {item.status}
                                            </p>
                                          </div>
                                        </div>

                                      </div>
                                    );
                                  }}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </div>
                        );
                      }}
                    </Droppable>
                  </div>
                </div>
              );
            })}
          </DragDropContext>
        </div >
      </section >
    </div >
  );
}

export default App;
